/// <reference path="o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { SyncType } from 'o365.pwa.types.ts';
import type { ApiRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";

import type * as ApiPwaStrategyModule from 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts';
import type { IApiPwaStrategyOptions, IFile, IOfflineSyncProgress, IOnlineSyncProgress, ITruncateProgress } from 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

// TODO: Add better error handling
(() => {
    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts");
    const { JsonDecoderStream } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.utilities.JsonDecoderStream.d.ts')>("o365.pwa.modules.sw.utilities.JsonDecoderStream.ts");
    const { restructureRecordForOfflineDB, restructureRecordForOnlineDB } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts')>("o365.pwa.modules.sw.O365OfflineDataRecord.ts");
    const { ApiPwaOfflineSyncOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.ts");
    const { ApiPwaOnlineSyncOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.ts");
    const { CrudHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.CrudHandler.d.ts')>("o365.pwa.modules.sw.CrudHandler.ts");
    const { FileCrudHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.FileCrudHandler.d.ts')>("o365.pwa.modules.sw.FileCrudHandler.ts");

    class ApiPwaStrategy extends self.workbox.strategies.Strategy implements ApiPwaStrategyModule.ApiPwaStrategy {
        private readonly mode: SyncType;

        constructor(options: IApiPwaStrategyOptions) {
            super(options);

            this.mode = options.mode;
        }

        _handle(request: Request, handler: StrategyHandler) {
            switch (this.mode) {
                case 'OFFLINE-SYNC':
                    return this.handleOfflineSync(request, handler);
                case 'ONLINE-SYNC':
                    return this.handleOnlineSync(request, handler);
                case 'TRUNCATE':
                    return this.handleTruncateData(request, handler);
                default:
                    throw new Error(`Invalid mode: \`${this.mode}\``);
            }
        }

        private async handleOfflineSync(request: Request, handler: StrategyHandler): Promise<Response> {
            const offlineSyncProgress = <IOfflineSyncProgress> {
                syncType: 'OFFLINE-SYNC',
                generateOfflineDataStarted: false,
                generateOfflineDataCompleted: false,
                generateOfflineDataCompletedWithError: false,

                retrieveRowCountStarted: false,
                retrieveRowCountCompleted: false,
                retrieveRowCountCompletedWithError: false,

                retrieveRecordsStarted: false,
                retrieveRecordsCompleted: false,
                retrieveRecordsCompletedWithError: false,

                retrieveFilesStarted: false,
                retrieveFilesCompleted: false,
                retrieveFilesCompletedWithError: false,

                recordsToRetrieve: 0,
                recordsRetrieved: 0,
                recordsRetrievedWithError: 0,
                recordsStored: 0,
                recordsStoredWithError: 0,

                filesToRetrieve: 0,
                filesRetrieved: 0,
                filesRetrievedWithError: 0,
                filesStored: 0,
                filesStoredWithError: 0,
            };

            try {
                const requestOptions = await ApiPwaOfflineSyncOptions.fromRequest(request);
                const parsedOptions = requestOptions.parsedOptions;

                const clientId = handler.event.clientId;
                const requestGuid = parsedOptions.requestGuid;

                offlineSyncProgress.requestGuid = requestGuid;

                await this.handleOfflineSyncGenerateOfflineData(requestOptions, offlineSyncProgress, clientId);

                if (offlineSyncProgress.generateOfflineDataCompletedWithError) {
                    return this.create200Response(offlineSyncProgress);
                }

                await Promise.allSettled([
                    this.handleOfflineSyncRowCount(requestOptions, clientId, offlineSyncProgress),
                    this.handleOfflineSyncRetrieve(requestOptions, clientId, offlineSyncProgress)
                ]);

                return this.create200Response(offlineSyncProgress);
            } catch (reason: any) {
                return this.create500Response(offlineSyncProgress, reason);
            }
        }

        private async handleOnlineSync(request: Request, handler: StrategyHandler): Promise<Response> {
            const onlineSyncProgress = <IOnlineSyncProgress> {
                syncType: 'ONLINE-SYNC',

                retrieveRowCountStarted: false,
                retrieveRowCountCompleted: false,
                retrieveRowCountCompletedWithError: false,

                uploadRecordsStarted: false,
                uploadRecordsCompleted: false,
                uploadRecordsCompletedWithError: false,

                uploadFilesStarted: false,
                uploadFilesCompleted: false,
                uploadFilesCompletedWithError: false,

                recordsToUpload: 0,
                recordsUploaded: 0,
                recordsUploadedWithError: 0,

                filesToUpload: 0,
                filesUploaded: 0,
                filesUploadedWithError: 0,
            };

            try {
                const requestOptions = await ApiPwaOnlineSyncOptions.fromRequest(request);
                const parsedOptions = requestOptions.parsedOptions;

                const clientId = handler.event.clientId;
                const requestGuid = parsedOptions.requestGuid;

                onlineSyncProgress.requestGuid = requestGuid;

                await this.handleOnlineSyncRowCount(requestOptions, clientId, onlineSyncProgress);

                if (onlineSyncProgress.retrieveRowCountCompletedWithError) {
                    return this.create200Response(onlineSyncProgress);
                }

                await this.handleOnlineSyncMerge(requestOptions, clientId, onlineSyncProgress);

                return this.create200Response(onlineSyncProgress);
            } catch (reason: any) {
                return this.create500Response(onlineSyncProgress, reason);
            }
        }

        private async handleTruncateData(request: Request, handler: StrategyHandler): Promise<Response> {
            const truncateProgress = <ITruncateProgress> {
                syncType: 'TRUNCATE',
            };

            try {

                let requestOptions = await ApiPwaOfflineSyncOptions.fromRequest(request);

                if (!requestOptions) throw new Error("Error occured when truncating data. Contact support.");

                const parsedOptions = requestOptions.parsedOptions;
                const clientId = handler.event.clientId;

                this.sendProgressUpdate(clientId, truncateProgress, { requestGuid: parsedOptions.requestGuid, truncateObjectStoreStarted: true });

                if (parsedOptions.fileStore) {
                    self.o365.logger.log("Truncate FileStore");
                } else {
                    await this.truncateData(parsedOptions);
                }
                
                this.sendProgressUpdate(clientId, truncateProgress, { truncateObjectStoreCompleted: true });

                return this.create200Response(truncateProgress);
            } catch (reason: any) {
                return this.create500Response(truncateProgress, reason);
            }
        }

        private async truncateData(options: InstanceType<typeof ApiPwaOnlineSyncOptions> | InstanceType<typeof ApiPwaOfflineSyncOptions>): Promise<void> {
            const appId = options.appIdOverride ?? options.appId;
            const databaseId = options.databaseIdOverride ?? "DEFAULT";
            const objectStoreId = options.objectStoreIdOverride ?? options.dataObjectId;

            const dexie = await CrudHandler.getDexieInstance({
                appId: appId,
                objectStoreId: objectStoreId,
                databaseIdOverride: databaseId,
                objectStoreIdOverride: options.objectStoreIdOverride,
            });

            await dexie.clear();
        }

        private async handleOfflineSyncGenerateOfflineData(
            requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>,
            offlineSyncProgress: IOfflineSyncProgress,
            clientId: string
        ): Promise<void> {
            try {
                const parsedOptions = requestOptions.parsedOptions;
                
                if ((parsedOptions.shouldGenerateOfflineData ?? false) === false) {
                    return;
                }

                this.sendProgressUpdate(clientId, offlineSyncProgress, { generateOfflineDataStarted: true });

                const generateOfflineDataBody = {
                    operation: 'execute',
                    procedureName: parsedOptions.offlineDataProcName,
                    timeout: parsedOptions.rowCountTimeout ?? 30,
                    useTransaction: true,
                    values: {
                        DeviceRef: parsedOptions.deviceRef,
                        AppID: parsedOptions.appId,
                        ViewName: parsedOptions.originalViewName
                    }
                };

                const generateOfflineDataRequest = new Request(`/nt/api/data/${parsedOptions.offlineDataProcName}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(generateOfflineDataBody)
                });

                const response = await fetch(generateOfflineDataRequest);

                if (!response.ok) {
                    let responseBodyString: string;

                    try {
                        responseBodyString = await response.text();
                    } catch (_) {
                        responseBodyString = 'Failed to read response body';
                    }

                    this.sendProgressUpdate(clientId, offlineSyncProgress, {
                        generateOfflineDataCompletedWithError: true,
                        generateOfflineDataError: {
                            errorCode: 'GENERATE-OFFLINE-DATA-RESPONSE-ERROR',
                            serializedErrorObject: responseBodyString,
                        }
                    });

                    return;
                }

                const json = await response.json();

                const jsonDataVersion = json?.success?.Table[0]?.O365_JsonDataVersion ?? null;

                const appId = parsedOptions.appIdOverride ?? parsedOptions.appId;
                const databaseId = parsedOptions.databaseIdOverride ?? "DEFAULT";
                const dataObjectId = parsedOptions.objectStoreIdOverride ?? parsedOptions.dataObjectId;

                const objectStoreRecord = await IndexedDBHandler.getObjectStore(appId, databaseId, dataObjectId);

                if (objectStoreRecord === null) {
                    this.sendProgressUpdate(clientId, offlineSyncProgress, {
                        generateOfflineDataCompletedWithError: true,
                        generateOfflineDataError: {
                            errorCode: 'GENERATE-OFFLINE-DATA-OBJECT-STORE-NOT-FOUND',
                        }
                    });

                    return;
                }
                if (jsonDataVersion !== null && (objectStoreRecord.jsonDataVersion !== jsonDataVersion)) {
                    this.sendProgressUpdate(clientId, offlineSyncProgress, {
                        generateOfflineDataCompletedWithError: true,
                        generateOfflineDataError: {
                            errorCode: 'GENERATE-OFFLINE-DATA-OBJECT-STORE-VERSION-MISMATCH',
                        }
                    });

                    return;
                }

                this.sendProgressUpdate(clientId, offlineSyncProgress, { generateOfflineDataCompleted: true });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                this.sendProgressUpdate(clientId, offlineSyncProgress, {
                    generateOfflineDataCompletedWithError: true,
                    generateOfflineDataError: {
                        errorCode: 'GENERATE-OFFLINE-DATA-UNKOWN-ERROR',
                        serializedErrorObject: stringifiedReason,
                    }
                });
            }
        }

        private async handleOfflineSyncRowCount(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string, offlineSyncProgress: IOfflineSyncProgress): Promise<void> {
            try {
                this.sendProgressUpdate(clientId, offlineSyncProgress, { retrieveRowCountStarted: true });

                const parsedOptions = requestOptions.parsedOptions;

                const rowCountBody = <{
                    dataObjectId: string,
                    fields: any,
                    maxRecords: number,
                    skip: number,
                    operation: string,
                    viewName: string,
                    masterDetailString?: string,
                    distinctRows?: boolean,
                    filterString?: string | null,
                    whereClause?: string | null
                    timeout: number
                }>{
                        dataObjectId: parsedOptions.dataObjectId,
                        fields: parsedOptions.fields,
                        maxRecords: -1,
                        skip: 0,
                        operation: 'rowcount',
                        viewName: parsedOptions.viewName,
                        masterDetailString: parsedOptions.masterDetailString,
                        distinctRows: parsedOptions.distinctRows,
                        filterString: null,
                        whereClause: null,
                        timeout: 30 ?? parsedOptions.rowCountTimeout,
                        expandView: false,
                        definitionProc: parsedOptions.definitionProc,
                        definitionProcParameters: parsedOptions.definitionProcParameters
                    };

                if (parsedOptions.shouldGenerateOfflineData ?? false) {
                    rowCountBody.whereClause = `[AppID] = '${parsedOptions.appId}' AND [Type] = '${parsedOptions.offlineDataType}' AND [Status] = 'UNSYNCED'`;
                }

                const filterString = (parsedOptions.filterString ?? '').trim();
                const whereClause = (parsedOptions.whereClause ?? '').trim();

                if (filterString.length > 0 && whereClause.length > 0) {
                    rowCountBody.filterString = `(${filterString}) AND (${whereClause})`;
                } else if (filterString.length > 0) {
                    rowCountBody.filterString = filterString;
                } else if (whereClause.length > 0) {
                    rowCountBody.filterString = whereClause;
                } else {
                    delete rowCountBody.filterString;
                }

                const rowCountRequest = new Request(`/nt/api/data/${parsedOptions.dataObjectId}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(rowCountBody)
                });

                const rowCountResponse = await fetch(rowCountRequest);
                const rowCountResponseJson = await rowCountResponse.json();

                // TODO: Add better parsing for potential errors

                if (rowCountResponseJson?.success?.total === 0 && parsedOptions.failOnNoRecords) {
                    this.sendProgressUpdate(clientId, offlineSyncProgress, {
                        retrieveRowCountCompletedWithError: true,
                        retrieveRowCountCompletedError: {
                            errorCode: 'RETRIEVE-ROW-COUNT-NO-RECORDS-ERROR'
                        }
                    });

                    return;
                }

                this.sendProgressUpdate(clientId, offlineSyncProgress, {
                    retrieveRowCountCompleted: true,
                    recordsToRetrieve: rowCountResponseJson?.success?.total
                });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                this.sendProgressUpdate(clientId, offlineSyncProgress, {
                    retrieveRowCountCompletedWithError: true,
                    retrieveRowCountCompletedError: {
                        errorCode: 'RETRIEVE-ROW-COUNT-UNKOWN-ERROR',
                        serializedErrorObject: stringifiedReason
                    }
                });
            }
        }

        private async handleOfflineSyncRetrieve(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string, offlineSyncProgress: IOfflineSyncProgress): Promise<void> {
            try {
                this.sendProgressUpdate(clientId, offlineSyncProgress, { retrieveRecordsStarted: true });

                const parsedOptions = requestOptions.parsedOptions;

                const retrieveBody = <{
                    dataObjectId: string,
                    fields: any,
                    maxRecords: number,
                    skip: number,
                    operation: string,
                    viewName: string,
                    masterDetailString?: string,
                    distinctRows?: boolean,
                    filterString?: string | null,
                    whereClause?: string | null
                }>{
                        dataObjectId: parsedOptions.dataObjectId,
                        fields: parsedOptions.fields,
                        maxRecords: -1,
                        skip: 0,
                        operation: 'retrieve',
                        viewName: parsedOptions.viewName,
                        masterDetailString: parsedOptions.masterDetailString,
                        distinctRows: parsedOptions.distinctRows,
                        filterString: null,
                        whereClause: null,
                        expandView: parsedOptions.expandView,
                        definitionProc: parsedOptions.definitionProc,
                        definitionProcParameters: parsedOptions.definitionProcParameters

                    };

                if (parsedOptions.shouldGenerateOfflineData ?? false) {
                    retrieveBody.whereClause = `[AppID] = '${parsedOptions.appId}' AND [Type] = '${parsedOptions.offlineDataType}' AND [Status] = 'UNSYNCED'`;
                }

                const filterString = (parsedOptions.filterString ?? '').trim();
                const whereClause = (parsedOptions.whereClause ?? '').trim();

                if (filterString.length > 0 && whereClause.length > 0) {
                    retrieveBody.filterString = `(${filterString}) AND (${whereClause})`;
                } else if (filterString.length > 0) {
                    retrieveBody.filterString = filterString;
                } else if (whereClause.length > 0) {
                    retrieveBody.filterString = whereClause;
                } else {
                    delete retrieveBody.filterString;
                }

                const retrieveRequest = new Request(`/nt/api/data/stream/${parsedOptions.dataObjectId}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(retrieveBody)
                });

                const retrieveResponse = await fetch(retrieveRequest);

                if (!retrieveResponse.ok) {
                    let retrieveResponseBodyText: string;

                    try {
                        retrieveResponseBodyText = await retrieveResponse.text();
                    } catch (_) {
                        retrieveResponseBodyText = 'Failed to parse retrieve response body'
                    }

                    this.sendProgressUpdate(clientId, offlineSyncProgress, {
                        retrieveRecordsCompletedWithError: true,
                        retrieveRecordsCompletedError: {
                            errorCode: 'RETRIEVE-RECORDS-RESPONSE-PARSE-ERROR',
                            serializedErrorObject: retrieveResponseBodyText
                        }
                    });

                    return;
                }

                const reader = retrieveResponse.body?.getReader();

                if (reader === undefined) {
                    this.sendProgressUpdate(clientId, offlineSyncProgress, {
                        retrieveRecordsCompletedWithError: true,
                        retrieveRecordsCompletedError: {
                            errorCode: 'RETRIEVE-RECORDS-RESPONSE-READER-MISSING-ERROR',
                        }
                    });

                    return;
                }

                const decoder = new JsonDecoderStream();

                const dexieInstance = await CrudHandler.getDexieInstance({
                    appId: parsedOptions.appId,
                    objectStoreId: parsedOptions.dataObjectId,
                    appIdOverride: parsedOptions.appIdOverride,
                    databaseIdOverride: parsedOptions.databaseIdOverride,
                    objectStoreIdOverride: parsedOptions.objectStoreIdOverride,
                });

                var numberOfRecords = 0;

                const insertPromises = new Array<Promise<void>>();
                const fileRecords = new Array<IFile>();

                const isFileView = retrieveBody.fields.some((field: any) => field.name === 'FileRef');

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }

                    const records: Array<any> = [];
                    
                    decoder.decodeChunk(value, (item: any) => {
                        if (parsedOptions.shouldGenerateOfflineData) {
                            item = restructureRecordForOfflineDB(item);

                            item.O365_Status = 'SYNCED';
                        } else {
                            Object.keys(item).forEach((key: any) => {
                                if (key.endsWith("_JSON")) {
                                    item[key] = JSON.parse(item[key]);
                                }
                            })
                        }

                        records.push(item);

                        if (isFileView) {
                            fileRecords.push(item);
                        }
                    });

                    const recordsToInsert = records.length;
                    numberOfRecords += recordsToInsert;

                    this.sendProgressUpdate(clientId, offlineSyncProgress, { recordsRetrieved: numberOfRecords });

                    if (records.length > 0) {
                        const bulkCreatePromise = new Promise<void>(async (resolve, reject) => {
                            try {
                                await dexieInstance.bulkPut(records);

                                offlineSyncProgress.recordsStored += recordsToInsert;

                                console.log('Records stored: ' + offlineSyncProgress.recordsStored);

                                this.sendProgressUpdate(clientId, offlineSyncProgress);

                                resolve();
                            } catch (reason: any) {
                                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                                offlineSyncProgress.recordsStoredError ??= new Array();
                                offlineSyncProgress.recordsStoredError.push({
                                    errorCode: 'RECORD-STORE-UNKOWN-ERROR',
                                    serializedErrorObject: stringifiedReason
                                });
                                offlineSyncProgress.recordsStoredWithError += recordsToInsert;

                                this.sendProgressUpdate(clientId, offlineSyncProgress);

                                console.log('Records failed to be stored');

                                reject(reason);
                            }
                        });

                        insertPromises.push(bulkCreatePromise);

                        records.length = 0;
                    }
                }

                reader.releaseLock();

                const bulkCreateResponses = await Promise.allSettled(insertPromises);

                const reasons = bulkCreateResponses.filter((promise) => promise.status === 'rejected').map((promise: PromiseSettledResult<void>) => (promise as PromiseRejectedResult).reason);

                if (reasons.length > 0) {
                    return;
                }

                this.sendProgressUpdate(clientId, offlineSyncProgress, { retrieveRecordsCompleted: true });
                
                if (isFileView) {
                    try {
                        this.sendProgressUpdate(clientId, offlineSyncProgress, {
                            retrieveFilesStarted: true,
                            filesToRetrieve: fileRecords.length,
                        });

                        await this.handleOfflineSyncDownloadFiles(fileRecords, requestOptions, clientId, offlineSyncProgress);

                        this.sendProgressUpdate(clientId, offlineSyncProgress, { retrieveFilesCompleted: true });
                    } catch (reason: any) {
                        const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
                        
                        this.sendProgressUpdate(clientId, offlineSyncProgress, {
                            retrieveFilesCompletedWithError: true,
                            retrieveFilesCompletedError: {
                                errorCode: 'RETRIEVE-FILES-UNKOWN-ERROR',
                                serializedErrorObject: stringifiedReason
                            }
                        });
                    }
                }
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
                
                this.sendProgressUpdate(clientId, offlineSyncProgress, {
                    retrieveRecordsCompletedWithError: true,
                    retrieveRecordsCompletedError: {
                        errorCode: 'RETRIEVE-RECORDS-UNKOWN-ERROR',
                        serializedErrorObject: stringifiedReason
                    }
                });
            }
        }

        private async handleOfflineSyncDownloadFiles(files: Array<IFile>, requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string, offlineSyncProgress: IOfflineSyncProgress) {
            const options = requestOptions.parsedOptions;

            const requests = new Array<Promise<any>>();
            
            for (const file of files) {
                const primKey = file.O365_PrimKey ?? file.PrimKey;
                if (this.canRetrievePdf(file.Extension)) {
                    file.PdfRef = self.crypto.randomUUID();
                    
                    offlineSyncProgress.filesToRetrieve += 2;
                    this.sendProgressUpdate(clientId, offlineSyncProgress);
                    
                    requests.push(this.handleOfflineSyncDownloadFile(file, "ORIGINAL", `/nt/api/file/download/${options.viewName}/${primKey}?scale=original`, clientId, requestOptions, offlineSyncProgress)); // original
                    requests.push(this.handleOfflineSyncDownloadFile(file, "PDF", `/nt/api/download-pdf/${options.viewName}/${primKey}`, clientId, requestOptions, offlineSyncProgress)); // pdf
                } else if (file.Extension === "pdf") {
                    offlineSyncProgress.filesToRetrieve += 1;
                    this.sendProgressUpdate(clientId, offlineSyncProgress);
                    
                    requests.push(this.handleOfflineSyncDownloadFile(file, "PDF", `/nt/api/download-pdf/${options.viewName}/${primKey}`, clientId, requestOptions, offlineSyncProgress)); // pdf
                } else if (this.isImage(file.Extension)) {
                    file.ThumbnailRef = self.crypto.randomUUID();
                    
                    offlineSyncProgress.filesToRetrieve += 2;
                    this.sendProgressUpdate(clientId, offlineSyncProgress);
                    
                    requests.push(this.handleOfflineSyncDownloadFile(file, "ORIGINAL", `/nt/api/file/download/${options.viewName}/${primKey}?scale=original`, clientId, requestOptions, offlineSyncProgress)); // optimized
                    requests.push(this.handleOfflineSyncDownloadFile(file, "THUMBNAIL", `/nt/api/file/download/${options.viewName}/${primKey}?scale=thumbnail`, clientId, requestOptions, offlineSyncProgress)); // thumbnail
                } else {
                    offlineSyncProgress.filesToRetrieve += 1;
                    this.sendProgressUpdate(clientId, offlineSyncProgress);
                    
                    requests.push(this.handleOfflineSyncDownloadFile(file, "ORIGINAL", `/nt/api/file/download/${options.viewName}/${primKey}`, clientId, requestOptions, offlineSyncProgress));
                }
            }

            const results = await Promise.allSettled(requests);

            // TODO: Add check on results?

            return results;
        }

        private canRetrievePdf(extension: string) {
            const dict = [
                ...["doc", "docx", "rtf", "dot", "dotx", "dotm", "docm", "odt", "ott"],
                ...["xls", "xlsx", "xlsb", "xlt", "xltx", "xltm", "xlsm", "ods"],
                ...["msg", "pst", "ost", "oft", "eml", "emlx", "mbox"],
                ...["ppt", "pptx", "pps", "pot", "ppsx", "pptm", "ppsm", "potx", "potm"],
                ...["txt", "csv"],
                ...["dxf", "cad", "dwg", "dwt", "plt", "cf2", "pcl", "hpgl", "dgn", "stl", "iges"]
            ];
            return dict.includes(extension);
        }

        private isImage(extension: string) {
            const dict = ["png", "jpeg", "jpg"];
            return dict.includes(extension);
        }

        public extractFilename = (header: string): string | null => {
            // Regular expression matches both with and without quotes

            const match = header.match(/filename="?([^"]+)"?/);
            return match ? match[1] : null;
        }

        private async handleOfflineSyncDownloadFile(file: IFile, type: FileType, url: string, clientId: string, requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, offlineSyncProgress: IOfflineSyncProgress) {
            try {
                const parsedOptions = requestOptions.parsedOptions;
                const request = new Request(url, { method: 'GET' });

                const response = await fetch(request);

                if (response.status !== 200) {
                    offlineSyncProgress.filesRetrievedError ??= new Array();
                    offlineSyncProgress.filesRetrievedError.push({
                        errorCode: 'FILE-RETRIEVE-UNKOWN-ERROR',
                    });
                    offlineSyncProgress.filesRetrievedWithError++;

                    this.sendProgressUpdate(clientId, offlineSyncProgress);

                    return;
                    // throw new Error(`Error retrieving file of type ${type}, ${response.status}: ${response.type} - ${await response.text()}`);
                }

                const responseBlob = await response.blob();
                
                offlineSyncProgress.filesRetrieved++;
                this.sendProgressUpdate(clientId, offlineSyncProgress);

                try {
                    let attach;

                    switch (type) {
                        case "ORIGINAL":
                            attach = {
                                PrimKey: file.FileRef,
                                PdfRef: file.PdfRef,
                                ThumbnailRef: file.ThumbnailRef,
                            };

                            break;

                        case "PDF":
                            const contentDisp = response.headers.get("Content-Disposition");

                            if (!contentDisp) throw Error("Content-Disposition undefined.");

                            attach = {
                                FileName: this.extractFilename(contentDisp) ?? file.FileName,
                                Extension: "pdf",
                                PrimKey: file.PdfRef ?? file.FileRef,
                            };

                            break;
                        case "THUMBNAIL":
                            attach = {
                                PrimKey: file.ThumbnailRef,
                            };

                            break;
                    }

                    let staticRecord = {
                        FileName: file.FileName,
                        Extension: file.Extension,
                        FileSize: responseBlob.size,
                        Data: responseBlob,
                        MimeType: responseBlob.type,
                        appID: parsedOptions.appIdOverride ?? parsedOptions.appId
                    };

                    const insertedFileRef = await FileCrudHandler.handleUpload({ ...staticRecord, ...attach, PrimKey: attach?.PrimKey! });
                    
                    offlineSyncProgress.filesStored++;
                    this.sendProgressUpdate(clientId, offlineSyncProgress);

                    return insertedFileRef;
                } catch (reason: any) {
                    const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                    offlineSyncProgress.filesStoredError ??= new Array();
                    offlineSyncProgress.filesStoredError.push({
                        errorCode: 'FILE-STORE-UNKOWN-ERROR',
                        serializedErrorObject: stringifiedReason
                    });
                    offlineSyncProgress.filesStoredWithError++;

                    this.sendProgressUpdate(clientId, offlineSyncProgress);
                }
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                offlineSyncProgress.filesRetrievedError ??= new Array();
                offlineSyncProgress.filesRetrievedError.push({
                    errorCode: 'FILE-RETRIEVE-UNKOWN-ERROR',
                    serializedErrorObject: stringifiedReason
                });
                offlineSyncProgress.filesRetrievedWithError++;

                this.sendProgressUpdate(clientId, offlineSyncProgress);
            }
        }

        private async handleOnlineSyncRowCount(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOnlineSyncOptions>>, clientId: string, onlineSyncProgress: IOnlineSyncProgress): Promise<void> {
            const requestBody = requestOptions.parsedOptions;
            
            try {
                this.sendProgressUpdate(clientId, onlineSyncProgress, { retrieveRowCountStarted: true });

                const rowsToSync = await CrudHandler.handleRetrieveRowcount({
                    appId: requestBody.appId,
                    dataObjectId: requestBody.dataObjectId,
                    objectStoreIdOverride: requestBody.objectStoreIdOverride,
                    fields: [],
                }, ['CREATED', 'UPDATED', 'DESTROYED'], []);

                const filesToSync = await CrudHandler.handleRetrieveRowcount({
                    appId: requestBody.appId,
                    dataObjectId: requestBody.dataObjectId,
                    objectStoreIdOverride: requestBody.objectStoreIdOverride,
                    fields: [],
                }, ['FILE-CREATED', 'FILE-UPDATED', 'FILE-DESTROYED'], []);

                this.sendProgressUpdate(clientId, onlineSyncProgress, { retrieveRowCountCompleted: true, recordsToUpload: rowsToSync + filesToSync, filesToUpload: filesToSync });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                this.sendProgressUpdate(clientId, onlineSyncProgress, {
                    retrieveRowCountCompletedWithError: true,
                    retrieveRowCountCompletedError: {
                        errorCode: 'RETRIEVE-ROW-COUNT-UNKOWN-ERROR',
                        serializedErrorObject: stringifiedReason
                    }
                });
            }
        }

        private async handleOnlineSyncMerge(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOnlineSyncOptions>>, clientId: string, onlineSyncProgress: IOnlineSyncProgress): Promise<void> {
            const requestBody = requestOptions.parsedOptions;

            const records = await this.retrieveRecords(requestBody);

            const recordsToSync = records.filter((record) => ['CREATED', 'UPDATED', 'DESTROYED'].includes(record.O365_Status));
            
            await this.uploadRecords(recordsToSync, clientId, requestBody, requestBody.truncateMode, onlineSyncProgress);

            const fileRecordsToSync = records.filter((record) => ['FILE-CREATED', 'FILE-UPDATED', 'FILE-DESTROYED'].includes(record.O365_Status))
            
            await this.uploadFiles(fileRecordsToSync, requestBody, clientId, onlineSyncProgress);

            if (onlineSyncProgress.uploadFilesCompletedWithError) {
                return;
            }

            await this.uploadFileRecords(fileRecordsToSync, clientId, requestBody, requestBody.truncateMode, onlineSyncProgress);
        }

        private async retrieveRecords(requestBody: any): Promise<any[]> {
            return CrudHandler.handleRetrieve({
                appId: requestBody.appId,
                objectStoreId: requestBody.dataObjectId,
                objectStoreIdOverride: requestBody.objectStoreIdOverride,
                fields: [],
            }, ['CREATED', 'UPDATED', 'DESTROYED', 'FILE-CREATED', 'FILE-UPDATED', 'FILE-DESTROYED']);
        }

        private async uploadRecords(records: any[], clientId: string, requestBody: any, truncateMode: TruncateIndexDBObjectStoreMode, onlineSyncProgress: IOnlineSyncProgress) {
            if (records.length === 0) {
                return;
            }

            this.sendProgressUpdate(clientId, onlineSyncProgress, { uploadRecordsStarted: true });

            const restructuredRecords = this.restructureRecords(records, "RECORD", requestBody);
            const options = this.getOptions("sstp_System_OfflineDataOnlineSync", restructuredRecords);
            
            const response = await this.sendDataToApi(options);

            if (!response.ok) {
                let responseBodyText: string;

                try {
                    responseBodyText = await response.text();
                } catch (_) {
                    responseBodyText = 'Failed to parse response body'
                }

                this.sendProgressUpdate(clientId, onlineSyncProgress, {
                    uploadRecordsCompletedWithError: true,
                    uploadRecordsCompletedError: {
                        errorCode: 'UPLOAD-RECORDS-UNKOWN-ERROR',
                        serializedErrorObject: responseBodyText
                    }
                });

                return;
            }

            await this.updateSyncedRecords(records, clientId, requestBody, truncateMode, onlineSyncProgress);
        }

        private async uploadFiles(records: any, requestBody: any, clientId: string, onlineSyncProgress: IOnlineSyncProgress) {
            try {
                this.sendProgressUpdate(clientId, onlineSyncProgress, { uploadFilesStarted: true });

                for (const appRecord of records) {
                    try {
                        const file = await FileCrudHandler.handleView({ FileRef: appRecord.FileRef });

                        if (!file || !file.dataAsBlob) {
                            throw new Error("Unable to retrieve file.");
                        }

                        const chunks = ApiPwaStrategy.chunkBlob(file.dataAsBlob);
                        
                        if (!chunks || chunks.length === 0) {
                            throw new Error("Something went wrong while creating chunks of file.");
                        }

                        let uploadRef: string | null = appRecord.PrimKey ?? null;

                        for (let chunk of chunks) {
                            const formData = new FormData();
                            
                            formData.append('File', chunk.chunk, file.filename);

                            if (!uploadRef) {
                                throw new Error("no UploadRef");
                            }

                            const requestUrl = `/nt/api/file/chunkupload/${uploadRef}`;

                            const response = await fetch(requestUrl, {
                                method: 'POST',
                                headers: new Headers({
                                    'Custom-Content-Range': chunk.ccr,
                                    'Accept': 'application/json'
                                }),
                                body: formData
                            });

                            const responseJson = await response.json();
                            
                            uploadRef = responseJson.uploadRef;
                            
                            const ccr = FileCrudHandler.getContentRange(chunk.ccr);

                            if (ccr?.end === ccr?.total! - 1 && responseJson.fileRef) { // uploadRef will be newest fileRef
                                const fileRef = responseJson.fileRef;

                                await FileCrudHandler.handleFileUpdate(file, Object.assign({ primKey: fileRef }));
                                
                                const newAppRecord = await CrudHandler.handleUpdate({
                                    appId: requestBody.appId,
                                    dataObjectId: requestBody.dataObjectId,
                                    objectStoreIdOverride: requestBody.objectStoreIdOverride,
                                    providedRecord: {
                                        ...appRecord,
                                        FileRef: fileRef,
                                        O365_Status: "SYNCED"
                                    }
                                });

                                appRecord.FileRef = newAppRecord.FileRef;
                            }
                        }

                        onlineSyncProgress.filesUploaded++;

                        this.sendProgressUpdate(clientId, onlineSyncProgress);
                    } catch (reason: any) {
                        const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                        onlineSyncProgress.filesUploadedError ??= new Array();
                        onlineSyncProgress.filesUploadedWithError++;
                        onlineSyncProgress.filesUploadedError.push({
                            errorCode: 'FILE-UPLOAD-UNKOWN-ERROR',
                            serializedErrorObject: stringifiedReason
                        });
                        
                        this.sendProgressUpdate(clientId, onlineSyncProgress, { uploadFilesCompletedWithError: true });

                        return;
                    }
                }

                this.sendProgressUpdate(clientId, onlineSyncProgress, { uploadFilesCompleted: true });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                this.sendProgressUpdate(clientId, onlineSyncProgress, {
                    uploadFilesCompletedWithError: true,
                    uploadFilesCompletedError: {
                        errorCode: 'UPLOAD-FILES-UNKOWN-ERROR',
                        serializedErrorObject: stringifiedReason
                    }
                })
            } 
        }

        private async uploadFileRecords(records: any[], clientId: string, requestBody: any, truncateMode: TruncateIndexDBObjectStoreMode, onlineSyncProgress: IOnlineSyncProgress) {
            if (records.length === 0) {
                return;
            }

            this.sendProgressUpdate(clientId, onlineSyncProgress, { uploadFilesStarted: true });
            
            const restructuredRecords = this.restructureRecords(records, "FILERECORD", requestBody);
            const options = this.getOptions("sstp_System_OfflineDataFilesOnlineSync", restructuredRecords);
            const response = await this.sendDataToApi(options);

            if (!response.ok) {
                let responseBodyText: string;

                try {
                    responseBodyText = await response.text();
                } catch (_) {
                    responseBodyText = 'Failed to parse response body'
                }

                this.sendProgressUpdate(clientId, onlineSyncProgress, {
                    uploadFilesCompletedWithError: true,
                    uploadFilesCompletedError: {
                        errorCode: 'UPLOAD-FILES-UNKOWN-ERROR',
                        serializedErrorObject: responseBodyText
                    }
                });
                
                return;
            }

            await this.updateSyncedRecords(records, clientId, requestBody, truncateMode, onlineSyncProgress);
        }

        private restructureRecords(records: any[], type: "RECORD" | "FILERECORD", requestBody: any): any[] {
            switch (type) {
                case 'FILERECORD':
                    return records.map(record => restructureRecordForOnlineDB(record)).map(record => [
                        record.PrimKey,
                        record.Created,
                        record.CreatedBy_ID,
                        record.Updated,
                        record.UpdatedBy_ID,
                        record.Status,
                        record.JsonData,
                        record.UpdatedFields,
                        record.OriginalValues,
                        record.JsonDataVersion,
                        record.FileName,
                        record.FileSize,
                        record.FileUpdated,
                        record.FileRef,
                        record.Type,
                        record.AppID,
                        requestBody.deviceRef
                    ]);
                case 'RECORD':
                    return records.map(record => restructureRecordForOnlineDB(record)).map(record => [
                        record.PrimKey,
                        record.Created,
                        record.CreatedBy_ID,
                        record.Updated,
                        record.UpdatedBy_ID,
                        record.Status,
                        record.JsonData,
                        record.UpdatedFields,
                        record.OriginalValues,
                        record.JsonDataVersion,
                        record.Type,
                        record.AppID,
                        record.ExternalRef,
                        requestBody.deviceRef
                    ]);
            }
        }

        private getOptions(procedureName: string, restructuredRecords?: any[]): any {
            if (restructuredRecords) {
                return {
                    Operation: "execute",
                    ProcedureName: procedureName,
                    UseTransaction: true,
                    Timeout: 60,
                    Values: { "OfflineData": restructuredRecords }
                };
            }
            return {
                Operation: "execute",
                ProcedureName: procedureName,
                UseTransaction: true,
                Timeout: 60,
            };
        }

        private async sendDataToApi(options: any): Promise<Response> {
            return await fetch('/nt/api/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(options),
            }) as Response;
        }

        private async updateSyncedRecords(records: any[], clientId: string, requestBody: any, truncateMode: TruncateIndexDBObjectStoreMode, onlineSyncProgress: IOnlineSyncProgress) {
            for (let record of records) {
                if (truncateMode === "TRUNCATE_AFTER_ONLINE_RECORD_SYNC") {
                    await CrudHandler.handleDestroy({
                        appId: requestBody.appId,
                        dataObjectId: requestBody.dataObjectId,
                        providedRecord: record,
                        objectStoreIdOverride: requestBody.objectStoreIdOverride
                    });

                    onlineSyncProgress.recordsUploaded++;

                    this.sendProgressUpdate(clientId, onlineSyncProgress);
                } else {
                    const updatedRecord = { ...record, O365_Status: "SYNCED" } as any;
                    
                    const response = await CrudHandler.handleUpdate({
                        appId: requestBody.appId,
                        dataObjectId: requestBody.dataObjectId,
                        providedRecord: updatedRecord,
                        objectStoreIdOverride: requestBody.objectStoreIdOverride
                    });
                    
                    if (response.O365_Status !== "SYNCED") {
                        onlineSyncProgress.recordsUploadedWithError++;

                        this.sendProgressUpdate(clientId, onlineSyncProgress);
                    } else {
                        onlineSyncProgress.recordsUploaded++;

                        this.sendProgressUpdate(clientId, onlineSyncProgress);
                    }
                }
            }
        }

        static chunkBlob(blob: Blob, chunkSize: number = (4 * 1024 * 1024)): Array<{ chunk: Blob, ccr: string }> {
            const chunks = [];

            for (let start = 0; start < blob.size; start += chunkSize) {
                const end = Math.min(start + chunkSize, blob.size);
                const chunk = blob.slice(start, end);
                chunks.push({ chunk: chunk, ccr: `bytes ${start}-${end - 1}/${blob.size}` });
            }

            return chunks;
        }

        private async sendProgressUpdate<T extends object = IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress>(clientId: string, progress: T, changes?: Partial<T>) {
            const client = await self.clients.get(clientId);

            if (changes) {
                Object.assign(progress, changes);
            }

            client?.postMessage(progress);
        }

        private create200Response(progress: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress): Response {
            console.log(progress);

            const responseBody = {
                progress: progress
            };

            const responseBodyString = JSON.stringify(responseBody);

            return new Response(responseBodyString, {
                status: 200,
                statusText: 'OK',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }

        private create500Response(progress: IOfflineSyncProgress | IOnlineSyncProgress | ITruncateProgress, reason: any): Response {
            const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

            const responseBody = {
                error: stringifiedReason,
                progress: progress
            };

            const responseBodyString = JSON.stringify(responseBody);

            return new Response(responseBodyString, {
                status: 500,
                statusText: 'Internal Server Error',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
    }

    self.o365.exportScripts({ ApiPwaStrategy });
})();
